
.single-cube-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  user-select: none;
  --edge-width: 400px;
  padding-top: calc(var(--edge-width) / 2.3333);

  .cube {
    position: relative;
    display: flex;
    margin: auto;
    width: var(--edge-width);
    height: var(--edge-width);

    transform-style: preserve-3d;
    //transform: rotateX(45deg) rotateY(45deg);
    //transform: rotateZ(-22.5deg) rotateY(-22.5deg) rotateX(45deg);
  }

  .cube div {
    position: absolute;
    width: var(--edge-width);
    height: var(--edge-width);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: bold;
    color: white;
    background-color: rgba(239, 24, 24, 0.7);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9);
  }

  .front {
    transform: translateZ(calc(var(--edge-width) / 2 + 0px));
  }

  .back {
    transform: rotateY(180deg) translateZ(calc(var(--edge-width) / 2 + 0px));
  }

  .right {
    transform: rotateY(90deg) translateZ(calc(var(--edge-width) / 2 + 0px));
  }

  .left {
    transform: rotateY(-90deg) translateZ(calc(var(--edge-width) / 2 + 0px));
  }

  .top {
    transform: rotateX(90deg) translateZ(calc(var(--edge-width) / 2 + 0px));
  }

  .bottom {
    transform: rotateX(-90deg) translateZ(calc(var(--edge-width) / 2 + 0px));
  }
}