.dice-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  user-select: none;
  --edge-width: 400px;
  padding-top: calc(var(--edge-width) / 2.3333);
  margin-bottom: calc(var(--edge-width) / 2.3333);

  @media (max-width: 750px) {
    --edge-width: 350px
  }
  @media (max-width: 610px) {
    --edge-width: 300px
  }
  @media (max-width: 520px) {
    --edge-width: 250px
  }
  @media (max-width: 440px) {
    --edge-width: 200px
  }
  @media (max-width: 350px) {
    --edge-width: 180px
  }

  .cube {
    position: relative;
    display: flex;
    margin: auto;
    width: var(--edge-width);
    height: var(--edge-width);

    transform-style: preserve-3d;
    //transform: rotateX(45deg) rotateY(45deg);
    //transform: rotateZ(-22.5deg) rotateY(-22.5deg) rotateX(45deg);
  }

  .cube div {
    position: absolute;
    width: var(--edge-width);
    height: var(--edge-width);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: bold;
    color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    //text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      -webkit-user-drag: none;
    }
  }

  .front {
    transform: translateZ(calc(var(--edge-width) / 2 + 0px));
  }

  .back {
    transform: rotateY(180deg) translateZ(calc(var(--edge-width) / 2 + 0px));
  }

  .right {
    transform: rotateY(90deg) translateZ(calc(var(--edge-width) / 2 + 0px));
  }

  .left {
    transform: rotateY(-90deg) translateZ(calc(var(--edge-width) / 2 + 0px));
  }

  .top {
    transform: rotateX(90deg) translateZ(calc(var(--edge-width) / 2 + 0px));
  }

  .bottom {
    transform: rotateX(-90deg) translateZ(calc(var(--edge-width) / 2 + 0px));
  }
}