.home-container {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /*padding: 6rem;*/
  /*min-height: 94vh;*/
  .fancy-card {
    margin-bottom: 18px;
  }
}
