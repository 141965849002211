.header {
  width: 100%;
  text-align: left;
  padding-left: 8px;
  //margin-top: auto;
  //padding: 20px;
  ////font-size: 10em;
  ////padding: 2rem 0;
  //text-align: center;
  //opacity: .6;
}

.headerWithHover {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}